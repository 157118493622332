import React from "react";
import useInBreakpoint from "../../hooks/useInBreakpoint";
import useIsLandscape from "../../hooks/useIsLandscape";

export default function StatBar(props) {
  const isLandscape= useIsLandscape();
  const isMobile = useInBreakpoint(0, isLandscape);

  return (
<div class="grid grid-cols-5 items-end">
<div class="col-start-1 col-span-6 p-0 m-0  flex items-end ">
    <p class={isMobile && isLandscape ? "inline font-black font-mono text-amber-50 text-lg uppercase drop-shadow-4xl shadow-black" : "inline font-black font-mono text-amber-50 tablet:text-3xl laptop:text-4xl uppercase drop-shadow-4xl shadow-black"}>{props.title}</p>
    <p class={isMobile && isLandscape ? "hidden" : "ml-2 text-yellow-200 inline mb-1 text-sm  desktop:text-base d"}>[</p>
     <p class={isMobile && isLandscape ? "hidden" : "inline text-slate-200 tabular-nums text-sm mb-1 desktop:text-base"}>{props.progress}</p>
    <p class={isMobile && isLandscape ? "hidden" :"text-yellow-200 inline  mb-1"}>]</p>
</div>
<div class="col-start-1 col-end-6">
     <div class="w-full h-5 bg-gray-200 rounded-md dark:bg-gray-700">
     <div class={props.color} ></div>
     </div >
</div>

</div>

  );
}