import about1_pic from "../../src/assets/about/Camp Confidante.jpeg"
import about2_pic from "../../src/assets/about/EAGLES 2018.jpeg"
import about3_pic from "../../src/assets/about/Edusave 2017.jpeg"
import about4_pic from "../../src/assets/about/geekout.jpeg"
import about5_pic from "../../src/assets/about/HighJump.jpeg"
import about6_pic from "../../src/assets/about/LongJump.jpeg"
import about7_pic from "../../src/assets/about/PD Camp Confidante.jpeg"
import about8_pic from "../../src/assets/about/pycon.jpeg"
import about9_pic from "../../src/assets/about/Stan Chart Campus Challenge 3rd.jpeg"
import about10_pic from "../../src/assets/about/StanChart.jpeg"
import about11_pic from "../../src/assets/about/WRS.jpeg"
import about12_pic from "../../src/assets/experience/SplashAwards First.jpeg"
import about13_pic from "../../src/assets/experience/Tableau3rd.jpeg"
import about14_pic from "../../src/assets/experience/TableauPresentation.jpeg"
import about15_pic from "../../src/assets/about/chickies.jpg"
import about16_pic from "../../src/assets/about/coffee.jpg"


const about = {
    headline: "An aspiring software developer based in sunny island, Singapore 🇸🇬 ♥️ !",
    intro: "I'm a fresh graduate from SMU, School of Computing and Information Systems. I'm an individual with many wild ideas, and I enjoy making these ideas come to life! I particularly enjoy designing and developing visual-related stuff.",
    facts: [
        "My Personality: Giver",
        "My Goal: Good all-rounder",
        "My Top Strengths: Adaptable, Empathetic, Learner",
        "I have been in 2 SAP schools."
    ],
    studentlife: {
        images: [

        ],
        content: [
            "Two-Time Track and Field Captain [2015-2016 Junior High Captain], [2017-2018 Overall Team Captain]",
            "Sports Leader",
            "Computing Club (DHS, 2017-2018)",
            "Social Media Coordinator for BuildingBloCS Workshop (2017-2018)",
            "Malay Cultural Club (DHS, 2016-2018)",
            "Student Mentor for Project Heartcode (SMU)",
            "Business Intelligence and Analytics"
        ]
    },
    academic_accomplishments:{
        images:[
            {img: about2_pic,
            caption:"EAGLES 2017"},
            {img:about3_pic,
            caption: "ECHA 2018"}
            ],
        content:[ "ECHA (Edusave Character Award) (2014)",
        "EAGLES (Edusave Award for Achievement, Good Leadership and Service) (2016/2017)",
        "School Service Award (2016/2017)",
        "SCIS Dean’s List for Academic Year (2020/2021, 2021/2022)",
        "SMU Magna Cum Laude (with High Distinction)"]
    },
    student_accomplishments: {
        images:[ 
            {img: about8_pic,
            caption:"Speaking in Python Conference"},
            {img:about12_pic,
            caption: "SplashAwards 2017"},
            {img:about13_pic,
            caption: "IMDA-Tableau 2017"},
            {img:about14_pic,
            caption: "Presenting in IMDA-Tableau"}
        ]
            ,
        content: {
            computing: [
                "Awardee for PayPal Women Luminaries Award (2022)",
                "Speaker for 'PyLadies' Introductory Python Workshop (2018)",
                "Speaker for Python Asian Pacific (APAC) for Micro:Bit (2018)",
                "1st in SplashAwards (2017)",
                "3rd in IMDA-Tableau Faceoff (2017)"
            ],
            track:[
            "8th/7th in High Jump in 2017/2018 National Inter-Schools",
            "8th in Long Jump in 2017 National Inter-Schools",
            "7th/6th in 4x100m Team Relays in 2017/2018 National Inter-Schools",
            "5th in 4x400m Team Relays in 2018 National Inter-Schools" 
            ]

        }

        
        
    },
    track_accomplishments:[
        "8th/7th in High Jump in 2017/2018 National Inter-Schools Championships",
        "8th in Long Jump in 2017 National Inter-Schools Championships",
        "7th/6th in 4x100m Team Relays in 2017/2018 National Inter-Schools Championships",
        "5th in 4x400m Team Relays in 2018 National Inter-Schools Championships" 
    ],
    giving_back:{
        images:[
            {img: about1_pic,
            caption:"Camp Confidante"},
            {img:about7_pic,
            caption: "Briefing volunteers"},
            {img:about9_pic,
            caption: "3rd in Standard Chartered Marathon"},
            {img:about10_pic,
            caption: "Standard Chartered Marathon Campus Challenge"},
            {img:about11_pic,
            caption: "Conversation Ambassador in Wildlife Reserves Singapore"}
            ],
        content:[
            "Mentor in Project Heartcode (2020), teaching secondary school students web development",
            "Project Director in Camp Confidante 2019, an inclusive camp for youth with and without Intellectual Disabilities",
            "Team Captain in Standard Chartered Marathon, Campus Challenge. Awarded as 3rd best Volunteer Team",
            "Conservation Ambassador for Wildlife Reserve Singapore"
        ]
    }
    ,
    computing: [ 

    ],
    hobbies: {
        content: [
        "Sports (Athletics, Cycling, Bouldering)",
        "Finance (Growth Investing)",
        "Cooking (or trying to...)",
        "Making Drinks (Coffee, Kombucha)",
        "Copywriting",
        "Video Editing",
        "Raising my pet chickens! 🐤🐦‍"],
        images: [
            {img:about5_pic,
            caption: "High Jump"},
            {img:about6_pic,
            caption: "Long Jump"},
            {img:about15_pic,
            caption: "My chickens!"},
            {img:about16_pic,
            caption: "Coffee!"}
        ]
    }
      
    
 };
  
  export default about;
  