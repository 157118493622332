import React, { useState } from 'react';
import rl_pic from '../../../src/assets/Character/rl_pic.png'
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import about from '../../data/about';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import Carousel from 'react-material-ui-carousel'
import { SvgIcon, Paper, Button} from '@mui/material';
import useIsMobile from '../../hooks/useIsMobile';
import useIsLandscape from '../../hooks/useIsLandscape';

const FactItems = about.facts.map((fact) =>
  <li>{fact}</li>
);

const HobbyItems = about.hobbies.content.map((hobby) =>
  <li>{hobby}</li>
);

const StudentLifeItems = about.studentlife.content.map((item) =>
  <li>{item}</li>
);

const AcademicAccomplishmentItems = about.academic_accomplishments.content.map((item) =>
  <li>{item}</li>
);

const ComputingAccomplishmentItems = about.student_accomplishments.content.computing.map((item) =>
  <li>{item}</li>
);

const TrackAccomplishmentItems = about.student_accomplishments.content.track.map((item) =>
  <li>{item}</li>
);

const VolunteerItems = about.giving_back.content.map((item) =>
  <li>{item}</li>
);

const AcademicImages = about.academic_accomplishments.images
const HobbyImages = about.hobbies.images
const StudentImages = about.student_accomplishments.images
const VolunteerImages = about.giving_back.images

function Item(props)
{
    return (
        <Paper>
            <img className="w-96 h-48" src={props.item.img}></img>
            <figcaption>{props.item.caption}</figcaption>
        </Paper>
    )
}

export default function Content() { 
    const [pg, setPg] = useState(1);
    const isMobile = useIsMobile();
    const isLandscape= useIsLandscape();
    console.log(isMobile)
    if (pg==1){
        return (
            <div className={
                isMobile
                ? isLandscape 
                    ? " h-full overflow-y-scroll rounded-md bg-slate-50 p-5 border" 
                    : "h-full overflow-y-scroll rounded-md bg-slate-50 p-5 border"
                :"tablet:basis-4/5 h-[90%] m-6 overflow-y-scroll rounded-md bg-slate-50 p-5 border"}>
                <div className="flex flex-row m-5">
                    <div className="tablet:basis-1/4 place-items-center mr-5">
                        <img className="rounded-2xl object-cover h-5/6 w-auto" src={rl_pic} alt="personal_pic" />
                    </div>
                    <div className="tablet:basis-3/4 text-start">
                    <p className="mobile:text-lg tablet:text-xl">Hey there, I'm <b>Pearlyn, 罗文晴</b>! </p>
                        <p className="mobile:text-sm tablet:text-base">{about.headline}</p><br></br>
                        <p className="mobile:text-sm tablet:text-base">{about.intro}</p><br></br>
                        <p className="mobile:text-sm tablet:text-base">Feel free to connect with me! 😊</p>
                        <a href="linkedin.com/pearlynloh"></a>
                        <a href="https://www.linkedin.com/in/pearlynloh/"><LinkedInIcon fontSize="large"></LinkedInIcon></a>
                        <a href="https://www.github.com/pearlynlohwq" className="mx-3"><GitHubIcon fontSize="large"></GitHubIcon></a>
                        <a href="mailto:pearlynlohwq@gmail.com"><EmailIcon fontSize="large"></EmailIcon></a>
                    </div>
                </div>
    
                <div className="flex place-content-end">
                        <Button variant="contained" color="primary" endIcon={<ArrowRightIcon />} onClick={() => setPg(pg + 1)}>NEXT</Button>
                </div>
            </div>
    )}
    // if (pg==2){
    //     return (
    //         <div className="lg:basis-4/5 rounded-md bg-slate-50 p-5 border ">
    //             <div className="flex flex-row m-5">
    //                 <div className="md:basis-3/5 text-start">
    //                     <p className="text-xl"><b>Here's some facts about me!</b></p>
    //                     <ul className="list-disc ml-3">{FactItems}</ul>
    //                 </div>                    
    //             </div>
    
    //             <div className="flex place-content-end">
    //                     <Button  variant="contained" color="success" endIcon={<ArrowLeftIcon />} onClick={() => setPg(pg - 1)}>BACK</Button>
    //                     <Button  sx={{ ml: 1 }} variant="contained" color="primary" endIcon={<ArrowRightIcon />} onClick={() => setPg(pg + 1)}>NEXT</Button>
    //             </div>
    //         </div>
    // )}
    if (pg==2){
        return (
            <div className={
                isMobile
                ?"h-full w-full overflow-y-scroll rounded-md bg-slate-50 p-5 border"
                :"tablet:basis-4/5 h-[90%] m-6 overflow-y-scroll rounded-md bg-slate-50 p-5 border"}>                
                <div className={isMobile && !isLandscape ? "flex flex-col m-5" : "flex flex-row m-5"}>
                    <div className="tablet:basis-3/5 text-start">
                    <p className="mobile:text-lg tablet:text-xl"><b>Some of my hobbies and interests...</b></p>
                        <ul className="list-disc ml-3 mobile:text-sm tablet:text-base">{HobbyItems}</ul>
                    </div>
                    <div className={isMobile && !isLandscape ? "tablet:basis-2/5 place-items-center mt-2" : "basis-2/5 place-items-center mr-1" }>
                    <Carousel>
                        {
                        HobbyImages.map( (item, i) => <Item key={i} item={item} /> )
                        }
                    </Carousel>
                    </div>
                </div>
                <div className="flex place-content-end">
                        <Button  variant="contained" color="success" endIcon={<ArrowLeftIcon />} onClick={() => setPg(pg - 1)}>BACK</Button>
                        <Button  sx={{ ml: 1 }} variant="contained" color="primary" endIcon={<ArrowRightIcon />} onClick={() => setPg(pg + 1)}>NEXT</Button>
                </div>
            </div>
    )}
    if (pg==3){
        return (
            <div className={
                isMobile
                ?"h-full w-full overflow-y-scroll rounded-md bg-slate-50 p-5 border"
                :"tablet:basis-4/5 h-[90%] m-6 overflow-y-scroll rounded-md bg-slate-50 p-5 border"}>                
                <div className={isMobile && !isLandscape ? "flex flex-col m-5" : "flex flex-row m-5"}>
                    <div className="tablet:basis-3/5 text-start">
                        <p className="text-xl mobile:text-lg tablet:text-xl"><b>My Student Life... </b></p>
                        <ul className="list-disc ml-3  mobile:text-sm tablet:text-base">{StudentLifeItems}</ul>
                    </div>
                    <div className={isMobile && !isLandscape ? "tablet:basis-2/5 place-items-center mt-2" : "basis-2/5 place-items-center mr-1" }>
                    <Carousel>
                        {
                        StudentImages.map( (item, i) => <Item key={i} item={item} /> )
                        }
                    </Carousel>
                    </div>
                </div>
    
                <div className="flex place-content-end">
                        <Button  variant="contained" color="success" endIcon={<ArrowLeftIcon />} onClick={() => setPg(pg - 1)}>BACK</Button>
                        <Button  sx={{ ml: 1 }} variant="contained" color="primary" endIcon={<ArrowRightIcon />} onClick={() => setPg(pg + 1)}>NEXT</Button>
                </div>
            </div>
    )}
    if (pg==4){
        return (
            <div className={
                isMobile
                ?"h-full w-full overflow-y-scroll rounded-md bg-slate-50 p-5 border"
                :"tablet:basis-4/5 h-[90%] m-6 overflow-y-scroll rounded-md bg-slate-50 p-5 border"}>                
                <div className={isMobile && !isLandscape ? "flex flex-col m-5" : "flex flex-row m-5"}>
                    <div className="tablet:basis-3/5 text-start">
                        <p className="mobile:text-lg tablet:text-xl"><b>Academic Accomplishments...</b></p>
                        <ul className="list-disc ml-3 mobile:text-sm tablet:text-base">{AcademicAccomplishmentItems}</ul>
                    </div>
                    <div className={isMobile && !isLandscape ? "tablet:basis-2/5 place-items-center mt-2" : "basis-2/5 place-items-center mr-1" }>
                    <Carousel>
                        {
                        AcademicImages.map( (item, i) => <Item key={i} item={item} /> )
                        }
                    </Carousel>
                    </div>
                </div>
    
                <div className="flex place-content-end">
                        <Button  variant="contained" color="success" endIcon={<ArrowLeftIcon />} onClick={() => setPg(pg - 1)}>BACK</Button>
                        <Button  sx={{ ml: 1 }} variant="contained" color="primary" endIcon={<ArrowRightIcon />} onClick={() => setPg(pg + 1)}>NEXT</Button>
                </div>
            </div>
    )}
    if (pg==5){
        return (
            <div className={
                isMobile
                ?"h-[full] w-full overflow-y-scroll rounded-md bg-slate-50 p-5 border"
                :"tablet:basis-4/5 h-[90%] m-6 overflow-y-scroll rounded-md bg-slate-50 p-5 border"}>                
                <div className={isMobile && !isLandscape ? "flex flex-col m-5" : "flex flex-row m-5"}>
                <div className="tablet:basis-3/5 text-start">
                    <p className="mobile:text-lg tablet:text-xl"><b>Other Accomplishments/Activities</b></p>
                        <p><b>Computing</b></p>
                        <ul className="list-disc ml-3 mobile:text-sm tablet:text-base">{ComputingAccomplishmentItems}</ul>
                        <br></br>
                        <p><b>Track & Field</b></p>
                        <ul className="list-disc ml-3 mobile:text-sm tablet:text-base">{TrackAccomplishmentItems}</ul>
                    </div>
                    <div className={isMobile && !isLandscape ? "tablet:basis-2/5 place-items-center mt-2" : "basis-2/5 place-items-center mr-1" }>
                    <Carousel>
                        {
                        StudentImages.map( (item, i) => <Item key={i} item={item} /> )
                        }
                    </Carousel>
                    </div>
                </div>
    
                <div className="flex place-content-end">
                        <Button  variant="contained" color="success" endIcon={<ArrowLeftIcon />} onClick={() => setPg(pg - 1)}>BACK</Button>
                        <Button  sx={{ ml: 1 }} variant="contained" color="primary" endIcon={<ArrowRightIcon />} onClick={() => setPg(pg + 1)}>NEXT</Button>
                </div>
            </div>
    )}
    if (pg==6){
        return (
            <div className={
                isMobile
                ?"h-full w-full overflow-y-scroll rounded-md bg-slate-50 p-5 border"
                             :"tablet:basis-4/5 h-[90%] m-6 overflow-y-scroll rounded-md bg-slate-50 p-5 border"}>                
                <div className={isMobile && !isLandscape ? "flex flex-col m-5" : "flex flex-row m-5"}>
                    <div className="tablet:basis-3/5 text-start">
                    <p className="tablet:text-xl mobile:text-lg"><b>Giving Back</b></p>
                        <ul className="list-disc ml-3 tablet:text-base mobile:text-sm">{VolunteerItems}</ul>
                    </div>
                    <div className={isMobile && !isLandscape ? "tablet:basis-2/5 place-items-center mt-2" : "basis-2/5 place-items-center mr-1" }>
                    <Carousel>
                        {
                        VolunteerImages.map( (item, i) => <Item key={i} item={item} /> )
                        }
                    </Carousel>
                    </div>
                </div>
                <div className="flex place-content-end">
                        <Button variant="contained" color="success" endIcon={<ArrowLeftIcon />} onClick={() => setPg(pg - 1)}>BACK</Button>
                </div>
            </div>
    )}
}
