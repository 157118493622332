import './assets/App.css';
import Navbar from './components/Navbar/Navbar'
import Details from './components/AboutMe/Details'
import Skills from './components/Skills/SkillBox'
import Project from './components/Projects/ProjectBox'
import { motion } from "framer-motion"
import Environment from './components/Main/Environment';
import boatquaytown_audio from '../src/assets/audio/BoatQuayTown.mp3'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { Fragment, ReactNode } from "react";
import useInBreakpoint from "./hooks/useInBreakpoint"
import useIsLandscape from './hooks/useIsLandscape';


function App() {
  const isMobile = useInBreakpoint(0, useIsLandscape());
  return (
    <Router>
      <div className="App">
        <Environment />
         <div className="layout ">
         <audio className={isMobile ? "m-3 h-[30px] w-[150px] absolute right-0": "m-3 absolute right-0"} controls autoPlay loop>
            <source src= {boatquaytown_audio} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
          <p className="absolute font-serif text-white italic text-start top-5 left-5">All art assets belong to Nexon,<br></br>this is only a fan-based personal project ♥️ </p>

          <Routes>
              <Route exact path='/' /> 
              <Route exact path='/about-me' element={<Details />} />
              <Route exact path='/skills' element={<Skills />} />
              <Route exact path='/projects' element={<Project />} />
            </Routes>
          </div> 
            <Navbar/>
      </div>
    </Router>
  );
}

export default App;
