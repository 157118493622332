import React, { useState } from 'react';
import book from '../../../src/assets/maplestory/old_book.png'
import CloseIcon from '@mui/icons-material/Close';
import {Link} from 'react-router-dom'
import skills from '../../data/skills'
import useIsLandscape from '../../hooks/useIsLandscape';
import useInBreakpoint from '../../hooks/useInBreakpoint';
import useDimensions from '../../hooks/useDimensions'

const uniqueCategories = new Set(skills.map((skill) => skill.category).filter((category) => !!category));
const categories = [...Array.from(uniqueCategories), "Misc"];

export default function SkillBox() { 
    const { width, height } = useDimensions();
    var boxHeight= width- 100;
    console.log(boxHeight)
    console.log(width,height)
    const isLandscape= useIsLandscape();
    const isMobile = useInBreakpoint(0, isLandscape);
    console.log(isLandscape)
    const [selectedCategory, setCategory] = useState('Fundamentals');
    var filteredSkills= skills.filter(skill=> skill.category == selectedCategory)
        
    function useLandscapeSkillBox(){
        return width>height && height<640 ;
    }
    const isLandscapeSkillbox = useLandscapeSkillBox()
    let skillList=filteredSkills.map((skill,index)=>{
        return (
            <a  href={skill.url}>
                <div className="flex flex-row  items-center  place-content-between shadow-inner mx-2 mb-1 bg-slate-100 ">
                <div className="basis-1/4 grid place-items-center  ">
                    <a href={skill.url}>
                        <img className= 'm-2 p-1 border-2 rounded-lg border-neutral-600 h-14 w-14' src={skill.logo}/>
                    </a>
                </div>
                <div className="basis-3/4 p-2 text-start">
                        <p className="bg-slate-200 p-1 mb-1 rounded-sm">{skill.label}</p>
                        <p className="bg-slate-200 p-1 rounded-sm">{skill.skillpt}</p>
                </div>
                </div>
            </a>
        )
    })

    function isCategory(cat){
        return selectedCategory ==  cat
    }

  return (
    
    <div className={isMobile 
        ? isLandscape 
            ? "my-5 box-border rounded-md  bg-slate-300 border border-gray-800 ring-2 ring-gray-500 mx-10 h-[95%] flex flex-col  w-4/6"
            : "my-5 box-border rounded-md  bg-slate-300 border border-gray-800 ring-2 ring-gray-500 mx-10 h-5/6 flex flex-col  w-3/4"
        : "my-20 box-border rounded-md  bg-slate-300 border border-gray-800 ring-2 ring-gray-500 ml-20 h-5/6 tablet:my-1/6 tablet:h-5/6 laptop:h-4/6 flex flex-col  tablet:mx-15 tablet:w-1/3 laptop:w-1/4"}>

    <div className=" rounded-md shadow-inner mx-3 my-2  bg-slate-100  border-4 border-gray-300 ring-2 ring-slate-600 	">
        <div className=" flex flex-row items-center  place-content-between "> 
        <p className='mx-2 text-sm font-mono text-start'>SKILL INVENTORY</p>
        <button className="bg-sky-300 text-sm border border-neutral-700 m-1 mx-2 rounded-md">
        <Link to="/">
            <CloseIcon sx={{ color: '#f2f0e9' }}></CloseIcon>      
        </Link>
        </button>
        </div>
    </div>
    <div className=" rounded-t-md shadow-inner  mx-2 bg-slate-100 border-b-8 border-red-900  	">
        <div className=" flex flex-row items-end "> 
        <button 
        onClick={() => setCategory(categories[0])}
        className={ isCategory(categories[0]) 
        ? 'bg-red-300 pt-1 text-neutral-50 font-bold rounded-t-lg w-20 shadow-inner border-2 border-neutral-700 text-base tablet:text-lg laptop:text-2xl' 
        : 'bg-stone-300 text-neutral-50 font-bold rounded-t-lg w-20 shadow-inner border-2 border-neutral-700 text-base tablet:text-lg laptop:text-2xl'}>0</button>
        <button 
        onClick={() => setCategory(categories[1])}
        className={ isCategory(categories[1]) 
        ? 'bg-red-300 pt-1 text-neutral-50 font-bold rounded-t-lg w-20 shadow-inner border-2 border-neutral-700 text-base tablet:text-lg laptop:text-2xl' 
        : 'bg-stone-300 text-neutral-50 font-bold rounded-t-lg w-20 shadow-inner border-2 border-neutral-700 text-base tablet:text-lg laptop:text-2xl'}>I</button>
        <button 
        onClick={() => setCategory(categories[2])}
        className={ isCategory(categories[2]) 
        ? 'bg-red-300 pt-1 text-neutral-50 font-bold rounded-t-lg w-20 shadow-inner border-2 border-neutral-700 text-base tablet:text-lg laptop:text-2xl ' 
        : 'bg-stone-300 text-neutral-50 font-bold rounded-t-lg w-20 shadow-inner border-2 border-neutral-700 text-base tablet:text-lg laptop:text-2xl'}>II</button>
        <button 
        onClick={() => setCategory(categories[3])}
        className={ isCategory(categories[3]) 
        ? 'bg-red-300 pt-1 text-neutral-50 font-bold rounded-t-lg w-20 shadow-inner border-2 border-neutral-700 text-base tablet:text-2xl' 
        : 'bg-stone-300 text-neutral-50 font-bold rounded-t-lg w-20 shadow-inner border-2 border-neutral-700 text-base tablet:text-lg laptop:text-2xl'}>III</button>
        <p className={isLandscapeSkillbox  ? 'text-inline mx-2 text-red-900 font-semibold': 'hidden'}>{selectedCategory}</p>
        </div>
    </div>
        <div className={isLandscapeSkillbox  ? "hidden": "flex flex-row  items-center  place-content-between shadow-inner mx-2 mb-1 bg-slate-100 "}>
                    <div className="basis-1/4 grid place-items-center  ">
                        <img className="h-12 w-12" src={book} alt="personal_pic" />
                    </div>
                    <div className="basis-3/4 p-2 text-start">
                        <div className="bg-gradient-to-b rounded-tr-xl from-amber-800 to-amber-700 h-3">&nbsp;</div>
                        <p className=" text-sm tablet:text-lg text-neutral-50 bg-slate-800 text-center p-1">{selectedCategory}</p>
                        <div className="bg-gradient-to-b rounded-br-xl from-amber-700 to-amber-600  h-3">&nbsp;</div>
                    </div>
        </div>
        <div className="overflow-y-scroll mr-2">
            <div className={isLandscapeSkillbox ? "grid grid-cols-2": "grid grid-col-1"}>
            {skillList}

            </div>
        </div>

        <div className={isMobile && isLandscape ? "hidden": "mb-2 mobile:p-1 tablet:p-3 border-t-2 border-slate-400 h-1/12 bottom-0 shadow-[inset_0_10px_9px_-7px_rgba(255,255,255,0.4)]"}>
        <div className=" items-center "> 

        <p className="text-white mobile:text-sm tablet:text-base laptop:text-lg font-mono">always learning to get better ♥️ </p>
        </div>
         </div>
    </div>
 
  );
}