const experience = {
  work: [
    {
      title: "PayPal",
      timeline: "May 2022 – August 2022",
      role: "Web Development Intern",
      description: "A multi-national technology company based in Germany, whose major business sector is mobility",
      remark:
        "A new fresh start for me after having finished my service for the country. I was able to learn a lot about processes, new techs and best practices, and apply to my daily work. And prominently, I could utilize my English in daily communications and formal meetings.",
      activities: [
        "Refined visuals of frontend components using React",
        "Tested and developed features for consumer-facing services which helped improve users’ experience on the PayPal web application. Automated functional tests were developed using Selenium",
        "Bug fixes and package maintenance to ensure continuous and smooth implementation of features within the team"
      ],
      techs: "HTML, CSS, NodeJs, ReactJS",
      links: [],
      category: "Internship"
    },
    {
      title: "GovTech",
      timeline: "Dec 2021 – April 2022",
      role: "Software Engineer Intern",
      description: "A Taiwanese life insurance company",
      remark:
        "This is the first company I joined after my graduation. I was able to experience a professional working environment and learned from my seniors.",
      activities: [
        "Used design thinking to iteratively prototype UI/UX of Insights Dashboard using Figma",
        "Developed and released Insights Dashboard using Flutter and Flutter BloC in SmartGym kiosks around Singapore",
        "Develop frontend layout of Admin website for ActiveSG Fitness Instructors using Flutter",
        "Updated code base to calculate treadmill speed for different treadmill models",
        "Set up sensors and debugged hardware/software issues during deployments"
      ],
      techs: "Flutter, JS, Python, Figma",
      links: [
        {name:"Medium Article", link:"https://medium.com/siot-govtech/fitness-lover-to-developer-building-a-smartgym-vision-592d92989905"}
      ],
      category: "Internship"
    },
  ],
  projects: [
    {
      title: "Online Booking System (Sponsor: Singapore Sports School)",
      timeline: "2022",
      description:
        "An Online Booking System of attraction passes",
      remark:
        "Our project was shortlisted as one of the top few projects by the sponsor to be considered for deployment.",
      activities: [
        "Full stack development of an online pass booking, and analytics system using Spring Boot, Java, Vue.js, MongoDB",
        "Frontend development using Vue.js and backend-frontend integration",
        "Automated email sending using Java Mail and Spring Scheduler",
        "Our project was shortlisted as one of the top few projects by the sponsor to be considered for deployment.",
      ],
      techs:"Spring Boot, Java, Vue.js, MongoDB",
      links: [
        {name:"Video Demo", 
        link:"https://youtu.be/eCrC0XpXKOY"},
        {name: "Presentation Deck",
        link: "https://drive.google.com/file/d/1jR-1ITC66vM1Dn95PyGDMugl5aGafK24/view?usp=sharing"}
        ],
      category: 'Website/Software'
    },
    {
      title: "Tackling False Alarms with Fire Detection Camera (Sponsor: SCDF)",
      timeline: "2021",
      description:
        "An end-to-end solution to detect false fire alarm using Computer Vision, and displaying it in a command-and-control dashboard of which users can execute functions on cameras tagged to their accounts",
      remark:
        "Final Year Project",
      activities: [
        "Full stack development of command-and-control dashboard using HTML, JS, CSS, Flask, and socket.io",
        "Integrated data from sensors to be displayed on dashboard",
        "Trained thermal and visual camera models using YOLOv5 to detect fire. "
      ],
      techs:"HTML, JS, CSS, Flask, socket.io, IoT",
      links: [{name: 'Demo Vid', link: "https://youtu.be/DnDJ5kNfGpY"}, 
      {name:"Report",link:"https://docs.google.com/document/d/1WlXXQYd9V8kfwmhE4ifKxJmVlklSgsbQ/edit?usp=sharing&ouid=106568866352314338364&rtpof=true&sd=true"}
      ],
      category: 'Website/Software'
    },
    {
      title: "Envisioning a Car-Lite Singapore Using GIS",
      timeline: "2020",
      description:
        "A Smart City Project using Geospatial Analytics",
      remark:
        "Two of our recommended cycling routes were similarly implemented by the Ministry of National Development!",
      activities: [
        "My team analysed Singapore’s current infrastructures to determine her readiness to be car lite in our Geographic Information Systems module. We tapped on open datasets and used ArcGIS for the analysis",
        "My team recommended new cycling tracks, new covered paths, and solar panels placements using buffering, weighted overlays, and sun-shadow analysis",
      ],
      techs:"ArcGIS, Python",
      links: [
        {name:"Final Report", link:         "https://docs.google.com/document/d/185lqZO9r6Lbn-pWriR9Ns8Gq7GsHbc5l/edit?usp=sharing&ouid=106568866352314338364&rtpof=true&sd=true"}
      ],
        category: 'Data Analytics'
    },
    {
      title: "IMDA-Tableau NIC Face-Off",
      timeline: "2017",
      description:
        "An Online Booking System of attraction passes",
      remark:
        "Emerged 3rd in “IMDA-Tableau NIC Face-Off 2017” held by Tableau and IMDA",
      activities: [
        "My team analysed the impacts social media has on Singaporean food culture by exploring commercial, cultural, and societal norms. Findings are presented using Tableau",
      ],
      techs:"Tableau, Python",
      links: [{name: 'Tableau Viz', link:"https://public.tableau.com/app/profile/tan.xiaochen/viz/FoodPrintDataAnalytics2017/FoodPrints"}],
      category: 'Competitions/Hackathons'
    },
    {
      title: "SplashAwards",
      timeline: "2017",
      description:
        "An Online Booking System of attraction passes",
      remark:
        "Emerged 1st in “SplashAwards 2017” held by Singapore Computing Society",
      activities: [
        "My team presented the application of drones in the education sector to aid the invigilation of examinations to maximise productivity in the education sector",
      ],
      links: [{name:"Article", link:"https://www.scs.org.sg/articles/splash-awards-winners-rise-up-to-the-drone-challenge"}],
      techs:"IoT, Python",
      category: 'Competitions/Hackathons'
    },
    {
      title: "Omdena, Exploring the Impact of Covid-19 using NLP",
      timeline: "2021",
      description:
        "This project aims to uncover the impact of Covid-19 on mental health in Singapore, by collecting and analysing public sentiments in the ongoing Covid-19 outbreak.",
      remark:
        "Insights and findings will be shared with mental health NGOs in Singapore, who can use the findings to make more informed decisions on designing future campaigns.",
      activities: [
        "Scraped data from twitter, as well as news outlets such as The Straits Time",
        "Analysed and explored the datasets using NLP techniques and built a public dashboard to showcase our findings.",
        "Conducted topic modelling to explore the range of topics and built a risk predictor to help identify online users at risk ofmental health issues."
      ],
      links: [
        {name: "Medium Article", link: "https://medium.com/omdena/exploring-the-impact-of-covid-19-on-mental-health-in-singapore-df68eae38309"}],
      techs:"IoT, Python",
      category: 'Data Analytics'
    },
    {
      title: "Digital Will Settlement Smart Contract",
      timeline: "2021",
      description:
        "An Online Booking System of attraction passes",
      remark:
        "Emerged 3rd in “IMDA-Tableau NIC Face-Off 2017” held by Tableau and IMDA",
      activities: [
        "Picked up basic Solidity in a basic Introduction to Blockchain class",
        "Implemented a sample smart contract that assists the process to validate transaction and releases crypto assets/crypto monies to the beneficiaries once the customers passed on"
      ],
      techs:"Tableau, Python",
      links: [{name:"Tech Spec Report", link:"https://docs.google.com/document/d/10bBzGVVUePdBncGV-5p-CFyYbZZ5qOEr/edit?usp=sharing&ouid=106568866352314338364&rtpof=true&sd=true"}],
      category: 'Blockchain'
    },
  ],
};

  export default experience;
  