import * as React from 'react';
import { useState, useRef, useEffect,useReducer } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import experience  from '../../data/experience';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

const uniqueWorkCategories = new Set(experience['work'].map((experience) => experience.category).filter((category) => !!category));
const workCategories = [...Array.from(uniqueWorkCategories)];

const uniqueSkillCategories = new Set(experience['projects'].map((experience) => experience.category).filter((category) => !!category));
const skillCategories = [...Array.from(uniqueSkillCategories)];

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square
  {...props} />
))(({ theme }) => ({

  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  margin: '0px 0px 10px 10px'
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<AddIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
    // marginTop: theme.spacing(1),
    color: '#fafafa',
    fontWeight:'bold',
    fontSize: 'large',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .09)'
      : 'rgb(167,205,223)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const initialState = {expanded: ['Internship'
]}
    function reducer(state, action) {
      switch (action.type) {
        case 'add':
          return {expanded: [...state.expanded, action.payload]};
        case 'remove':
          return {expanded: state.expanded.filter(item => item!==action.payload)};
        case 'reset':
          return {expanded: action.payload};
        default:
          throw new Error();
      }
    }

export default function StyledAccordion(props) {
    const uniqueCategories = new Set(experience[props.selected].map((experience) => experience.category).filter((category) => !!category));
    var categories = [...Array.from(uniqueCategories)];
    var experiences= experience[props.selected]

 
    useEffect(()=> {
      dispatch({type: 'reset',payload: categories})
    }, [props.selected])

    const [state, dispatch] = useReducer(reducer, initialState);
    const [projectDetails, setProjectDetails]= useState(experiences[0])
    const [selectedExp, setSelectedExp] = useState(experiences[0].title);

    useEffect(()=> {
      setSelectedExp(experiences[0].title)
      setProjectDetails(experiences[0])
    }, [props.selected])

 
    useEffect(()=> {
      props.onSelect(projectDetails)
    }, [selectedExp])
      

    function handleSelect(exp){
      setSelectedExp(exp.title)
      setProjectDetails(exp)
    }

    function isExperience(exp){
        return selectedExp ==  exp
    }

    const handleChange = (panel) => (event, newExpanded) => {
      newExpanded ? dispatch({type: 'add',payload: panel}) : dispatch({type: 'remove',payload: panel}) 
    };

    let accordionList=categories.map((category,index)=>{
        var filteredExperience= experiences.filter(experience=> experience.category == category)
        let experienceList= filteredExperience.map((exp, index)=>{
            return (
            <button 
                onClick={() => handleSelect(exp)}

                className="inline-flex items-start my-2">
                <PriorityHighIcon sx={{ color: 'orange'}}/ >
                <span className= { isExperience(exp.title) 
                ? "text-start bg-sky-700 p-1 text-neutral-100 font-semibold w-full" 
                : "text-start " 
                }
                >{exp.title}</span>
            </button>
            )
        })
        
        return (
            <Accordion
           expanded={state.expanded.includes(category) } onChange={handleChange(category)}
            >
        <AccordionSummary aria-controls= '{category}-content' id= '{category}-header'
               expandIcon={state.expanded.includes(category) 
                ? <RemoveIcon sx={{color: "white", backgroundColor: "#7cd3fc", border: "1px solid black", borderRadius: 1}}/>
                : <AddIcon sx={{color: "white", backgroundColor: "#7cd3fc", border: "1px solid black", borderRadius: 1}}/>}
               >
          <Typography>{category}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className="grid ">
            {experienceList}

            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
        )
    })

  return (
    <div  >
        {accordionList}
    </div>
  );
}