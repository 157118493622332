import React, { useState } from 'react';
import personal_pic from '../../../src/assets/Character/CharacterSpriteSheetRight/love/0/jump_0.png'
import rl_pic from '../../../src/assets/Character/rl_pic.png'
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { SvgIcon, Button} from '@mui/material';
import about from '../../data/about';
import {Link} from 'react-router-dom'
import Content from './Content'
import useIsMobileLandscape from '../../hooks/useIsMobileLandscape';
import useIsMobile from '../../hooks/useIsMobile';
import useDimensions from '../../hooks/useDimensions';
import useInBreakpoint from '../../hooks/useInBreakpoint';
export default function Details() { 
    const isMobile = useIsMobile();
    const isMobileLandscape = useIsMobileLandscape();
    const isMedScreen= useInBreakpoint(1);
    const {width, height}= useDimensions();
    const marginx= width/24
    const marginy= (height* 0.8)/12
    return (
        
 <div className=
    {isMobile
    ? isMobileLandscape 
        ? "rounded-md h-[95%] m-2  relative flex flex-col place-content-center bg-slate-100 border border-gray-800 ring-2 ring-gray-500"
        : "rounded-md h-[95%] m-3 relative flex flex-col  w-3/4 mobile:w-11/12 tablet:w-10/12 bg-slate-100 border border-gray-800 ring-2 ring-gray-500"
    : height < 850 
    ? "rounded-md h-5/6  relative fixed  m-auto  mt-[2%] flex flex-col justify-center	 justify-self-center align-center  w-3/4 bg-slate-100 border border-gray-800 ring-2 ring-gray-500"
    :"rounded-md tablet:h-5/6  relative fixed laptop:h-auto m-auto tablet:mt-[8%] laptop:mt-[10%] desktop:mt-[10%]  flex flex-col justify-center	 justify-self-center align-center  w-5/6 bg-slate-100 border border-gray-800 ring-2 ring-gray-500"}>
        <div className=" rounded-md shadow-inner relative flex flex-1 h-[70%] w-[98%]  mb-16 mt-2  self-center bg-orange-50 border-4 border-gray-300 ring-2 ring-slate-600 	">
                <div className={
                    isMedScreen 
                    ? "hidden"
                    : "basis-1/5 border-r ml-3 border-gray-400  grid sm:hidden grid-cols-1 content-center"}>
                    <img src={personal_pic} alt="personal_pic" />
                    <p className="bg-sky-900 border border-gray-400 text-neutral-100 mx-2">Pearlyn</p>
                </div>
                <Content/>
        </div>
        {/* <div className="rounded-b-md flex items-center border border-gray-200 h-10 p-7  mt-2 place-content-between bg-slate-300"> */}
        <div className=" absolute bottom-0 w-full rounded-b-md flex items-center border border-gray-200 h-10 p-7  place-content-end bg-slate-300">

            {/* <Link to="/">
                <button className="font-mono border border-neutral-600 tracking-widest bg-green-800 px-6 py-1 rounded-md drop-shadow-lg text-white">CLOSE ABOUT ME</button>
            </Link> */}
            <Link to="/">
                <button className="font-mono border border-neutral-600 tracking-widest bg-orange-500  px-6 py-1 rounded-md drop-shadow-lg text-white">OK</button>
            </Link>
        </div>
        </div>
 

  );
}