import '../../assets/App.css';
import boatquaytown_audio from '../../assets/audio/BoatQuayTown.mp3'
import { Canvas, useFrame, useLoader, extend, createRoot, events } from "react-three-fiber";
import * as THREE from "three";
import { OrbitControls, Sky, useVideoTexture, useTexture } from '@react-three/drei';

import map from '../../assets/Boat Quay Town/Rendered Map.png'
import sky from '../../assets/Boat Quay Town/Sky BG (1).png'
import water from '../../assets/Boat Quay Town/Water.png'
import watershine from '../../assets/Boat Quay Town/Water Shine.png'
import char_walking from '../../assets/Character/walk.png'
import char_sit_left from '../../assets/Character/sit_left.png'
import merlion from '../../assets/Boat Quay Town/Merlion.png'
import distant_building1 from '../../assets/Boat Quay Town/Distant Buildings (1).png'
import distant_building2 from '../../assets/Boat Quay Town/Distant Buildings (2).png'
import distant_building3 from '../../assets/Boat Quay Town/Distant Buildings (3).png'
import tile from '../../assets/Boat Quay Town/Tile (with water) (4).png'
import trees from '../../assets/Boat Quay Town/Row of Trees.png'
import sewer_water from '../../assets/Boat Quay Town/Sewer Water Sprite.png'
import cloud1 from '../../assets/Boat Quay Town/Clouds (White) (4).png'
import cloud2 from '../../assets/Boat Quay Town/Clouds (White) (2).png'
import cloud3 from '../../assets/Boat Quay Town/Clouds (Purple) (5).png'
import speech from '../../assets/speech/speech.png'
import speech1 from '../../assets/speech/speech1.png'
import speech2 from '../../assets/speech/speech2.png'
import speech3 from '../../assets/speech/speech3.png'

import { VideoTexture } from 'three';

function BgTile(){
  const texture= useLoader(THREE.TextureLoader, tile)
  texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(50,1)
  texture.offset.x= 0
  texture.offset.y= 0

  return (
    <mesh position={[0,-2.2,0]}>
      <planeBufferGeometry args={[50,1.5]}/>
      <meshStandardMaterial transparent map={texture}/>
    </mesh>
  )
}

function BgTree(){
  const texture= useLoader(THREE.TextureLoader, trees)
  texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(4,1)
  texture.offset.x= 0
  texture.offset.y= 0

  return (
    <mesh position={[0,-0.7,0]}>
      <planeBufferGeometry args={[20,1.5]}/>
      <meshStandardMaterial transparent map={texture}/>
    </mesh>
  )
}

function Cloud1(){
  const texture= useLoader(THREE.TextureLoader, cloud1)
  texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(15,1)
  texture.offset.x= 1/1000
  texture.offset.y= 0
  var temp_time = 0
  
  useFrame(({clock}, delta)=>{
    if (temp_time > 0.015){
      if (texture.offset.x ==1){
        texture.offset.x=0
      }
      texture.offset.x+= 1/1000
      temp_time=0
    }
    temp_time+= delta
  })

  return (
    <mesh position={[0,2.3,0]}>
      <planeBufferGeometry args={[100,2]}/>
      <meshStandardMaterial transparent map={texture}/>
    </mesh>
  )
}
function Cloud2(){
  const texture= useLoader(THREE.TextureLoader, cloud2)
  texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(15,1)
  texture.offset.x= 1/1000
  texture.offset.y= 0
  var temp_time = 0
  
  useFrame(({clock}, delta)=>{
    if (temp_time > 0.05){
      if (texture.offset.x ==3/3){
        texture.offset.x=0
      }
      texture.offset.x+= 1/1000
      temp_time=0
    }
    temp_time+= delta
  })

  return (
    <mesh position={[0,1.7,0]}>
      <planeBufferGeometry args={[100,1.5]}/>
      <meshStandardMaterial transparent map={texture}/>
    </mesh>
  )
}
function Cloud3(){
  const texture= useLoader(THREE.TextureLoader, cloud3)
  texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(17.5,1)
  texture.offset.x= 0
  texture.offset.y= 0
  var temp_time = 0
  
  useFrame(({clock}, delta)=>{
    if (temp_time > 0.08){
      if (texture.offset.x ==3/3){
        texture.offset.x=0
      }
      texture.offset.x+= 1/1000
      temp_time=0
    }
    temp_time+= delta
  })

  return (
    <mesh position={[0,1.3,0]}>
      <planeBufferGeometry args={[100,2]}/>
      <meshStandardMaterial transparent map={texture}/>
    </mesh>
  )
}

function DistBuilding1(){
  const texture= useLoader(THREE.TextureLoader, distant_building1)
  texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(1,1)
  texture.offset.x= 0
  texture.offset.y= 0

  return (
    <mesh position={[0,1,0]}>
      <planeBufferGeometry args={[9,5.5]}/>
      <meshStandardMaterial transparent map={texture}/>
    </mesh>
  )
}

function DistBuilding2(){
  const texture= useLoader(THREE.TextureLoader, distant_building2)
  texture.repeat.set(1,1)
  texture.offset.x= 0
  texture.offset.y= 0

  return (
    <mesh position={[7,0,0]}>
      <planeBufferGeometry args={[10,3]}/>
      <meshStandardMaterial transparent map={texture}/>
    </mesh>
  )
}


function DistBuilding3(){
  const texture= useLoader(THREE.TextureLoader, distant_building3)
  texture.repeat.set(1,1)
  texture.offset.x= 0
  texture.offset.y= 0

  return (
    <mesh position={[-2,0,0]}>
      <planeBufferGeometry args={[10,3]}/>
      <meshStandardMaterial transparent map={texture}/>
    </mesh>
  )
}

function Water(){
  const texture= useLoader(THREE.TextureLoader, water)
  texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(10,1)
  texture.offset.x= 1/100
  texture.offset.y= 0
  var temp_time = 0 
  temp_time= 0

  useFrame(({clock}, delta)=>{
    if (temp_time > 0.15){
      if (texture.offset.x ==5/5){
        texture.offset.x=0
      }
      texture.offset.x+= 1/100
      temp_time=0
    }
    temp_time+= delta
  })
  return (
    <mesh position={[0,-2.7,0]}>
      <planeBufferGeometry args={[100,1.5]}/>
      <meshStandardMaterial transparent map={texture}/>
    </mesh>
  )
}


function WaterShine(){
  const texture= useLoader(THREE.TextureLoader, watershine)
  texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(5,1)
  texture.offset.x= 1/100
  texture.offset.y= 0
  var temp_time = 0 
  temp_time= 0

  useFrame(({clock}, delta)=>{
    if (temp_time > 0.15){
      if (texture.offset.x ==5/5){
        texture.offset.x=0
      }
      texture.offset.x+= 1/100
      temp_time=0
    }
    temp_time+= delta
  })
  return (
    <mesh position={[0,-2.7,0]}>
      <planeBufferGeometry args={[25,1.5]}/>
      <meshStandardMaterial transparent map={texture}/>
    </mesh>
  )
}

function SewerWater(){
  const texture= useLoader(THREE.TextureLoader, sewer_water)
  texture.repeat.set(1/3,1)
  texture.offset.x= 0
  texture.offset.y= 0
  var temp_time = 0

  useFrame(({clock}, delta)=>{
    if (temp_time> 0.15){
      if (texture.offset.x ==2/3){
        texture.offset.x=0
      }
      texture.offset.x+= 1/3
      temp_time=0
    }
    temp_time+= delta

  })
  return (
    <mesh position={[-2.7,-2.28,0]}>
      <planeBufferGeometry args={[0.5,0.8]}/>
      <meshStandardMaterial transparent map={texture}/>
    </mesh>
  )
}

function Merlion(){
  const texture= useLoader(THREE.TextureLoader, merlion)
  texture.repeat.set(1/3,1)
  texture.offset.x= 0
  texture.offset.y= 0
  var temp_time = 0

  useFrame(({clock}, delta)=>{
    if (temp_time> 0.15){
      if (texture.offset.x ==2/3){
        texture.offset.x=0
      }
      texture.offset.x+= 1/3
      temp_time=0
    }
    temp_time+= delta

  })
  return (
    <mesh position={[2.6,-0.19,0]}>
      <planeBufferGeometry args={[3,5]}/>
      <meshStandardMaterial transparent map={texture}/>
    </mesh>
  )
}

function SpriteWalkRight(){
  const texture= useLoader(THREE.TextureLoader, char_walking)
  texture.repeat.set(1/4,1/2)
  texture.offset.x= 0
  texture.offset.y= 0
  var temp_time = 0
  
  useFrame(({clock}, delta)=>{
    if (temp_time > 0.15){
      if (texture.offset.x ==3/4){
        texture.offset.x=0
      }
      texture.offset.x+= 1/4
      temp_time=0
    }
    temp_time+= delta
  })

  return (
    <mesh>
      <planeBufferGeometry args={[4,1]}/>
      <meshStandardMaterial transparent map={texture}/>
    </mesh>
  )
}

function CharSitLeft(){
  const texture= useLoader(THREE.TextureLoader, char_sit_left)
  texture.repeat.set(1,1)
  texture.offset.x= 1/2 
  texture.offset.y= 0
  var temp_time = 0
  
  // useFrame(({clock}, delta)=>{
  //   if (temp_time > 0.13){
  //     if (texture.offset.x ==1){
  //       texture.offset.x=1/2
  //     }
  //     texture.offset.x = 0
  //     temp_time=0
  //   }
  //   temp_time+= delta
  // })
  return (
    <mesh position={[1.4,0.56,0]}>
      <planeBufferGeometry args={[4,1]}/>
      <meshBasicMaterial transparent map={texture}  />
    </mesh>
  )
}

function SpriteWalkLeft(){
  const texture= useLoader(THREE.TextureLoader, char_walking)
  texture.repeat.set(1/4,1/2)
  texture.offset.x= 0
  texture.offset.y= 0.5
  var temp_time = 0
  
  useFrame(({clock}, delta)=>{
    if (temp_time > 0.15){
      if (texture.offset.x ==3/4){
        texture.offset.x=0
      }
      texture.offset.x+= 1/4
      temp_time=0
    }
    temp_time+= delta
  })
  return (
    <mesh position={[0,-0.9,0]}>
      <planeBufferGeometry args={[2,1]}/>
      <meshBasicMaterial transparent map={texture}  />
    </mesh>
  )
}

function SpeechBubble() {
  <mesh position={[0,0,0]}>
  <planeBufferGeometry args={[2,1]}/>
  <meshBasicMaterial>
  <videoTexture attach={speech} />

  </meshBasicMaterial>
</mesh>
}

function BgMap(){
  const texture= useLoader(THREE.TextureLoader, map)
  return (
    <mesh position={[-6.5,0,0]}>
      <planeBufferGeometry args={[31,6]}/>
      <meshBasicMaterial transparent map={texture} />
    </mesh>
  )
}

function BgSky(){
  const texture= useLoader(THREE.TextureLoader, sky)
  texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(5000,1)
  texture.offset.x= 0
  texture.offset.y= 0
  return (
    <mesh position={[0,0,0]}>
      <planeBufferGeometry args={[100,10]}/>
      <meshStandardMaterial transparent map={texture} color={'transparent'} />
    </mesh>
  )
}

//Character Controls

//Character Speech
function Speech1(){
  const texture= useLoader(THREE.TextureLoader, speech)

  texture.repeat.set(1/5,1/30)
  texture.offset.x= 0/5
  texture.offset.y= 29/30
  texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

  var temp_time = 0
  var no_tiles= 150 
  var curr_tile= 1

  useFrame(({clock}, delta)=>{
    if (temp_time> 0.05){
      if(curr_tile == no_tiles){
        curr_tile =1           
        texture.offset.x= 0/5
        texture.offset.y= 29/30
      }

      if (texture.offset.x ==4/5){
        texture.offset.x= -1/5
        if (texture.offset.y!= 1/30){
          texture.offset.y-= 1/30
        }
      }
      texture.offset.x+=1/5
      curr_tile+=1

      temp_time=0
    }
    temp_time+= delta
  })
  return (
    <mesh position={[0.5,1.35,0]}>
      <planeBufferGeometry args={[3.5,0.8]}/>
      <meshStandardMaterial transparent map={texture}/>
    </mesh>
  )
}
  
function Environment() {
  return (
        <div id="canvas">
          <Canvas>
            <ambientLight intensity={0.6} />
            {/* <spotLight position={[0,5,1]}/> */}
            <BgSky position={[0,0,0]}  />
            <Cloud3/>
            <Cloud2/>
            <Cloud1/>
            <DistBuilding1/>
            <DistBuilding2/>
            <DistBuilding3/>
            <BgTree/>
            <BgTile/>
            <Merlion/>
            <Water />
            {/* <WaterShine/> */}
            <BgMap  />
            <SewerWater />
            <CharSitLeft />
            <SpeechBubble />
            <Speech1/>
            {/* <SpriteWalkLeft  position={[-6, 0, 0]}/> */}

          </Canvas>
        </div>
        
  );
}

export default Environment;
