import React from "react";

export default function ProjectDetails(props) {
    console.log(props)
    const activities = props.experience.activities.map((item) =>
        <li>{item}</li>
    );
    
    const links= props.experience.links.map((item)=>
    <div>
        <a className="text-sky-700 hover:text-sky-500" href={item.link}>{item.name}</a>
        <br></br>
    </div>
    );
    

  return (
    <div className="rounded-tr-md bg-slate-300 border-y border-r border-gray-800 shadow-[inset_10px_0_9px_-7px_rgba(0,0,0,0.6)] ring-2 ring-gray-500 w-1/2 relative">
        <div className="rounded-r-md  mb-4 p-3  	">
        &nbsp;
        </div>
        <div className=" bg-slate-100 shadow-[inset_10px_0_9px_-7px_rgba(0,0,0,0.6)]  border-t-2 border-slate-400  grid place-items-end overflow-hidden">
            <div className="bg-sky-600 shadow-[inset_0_7px_9px_-7px_rgba(0,0,0,0.4)] contrast-100 mt-10 p-3  rounded-md border border-gray-600 grid place-items-start w-3/4 mr-5 absolute -top-[0.1px] ">
            <p className="text-lg capitalize font-bold text-neutral-100">{props.experience.title}</p>
            <p className="text-neutral-100">{props.experience.role}</p>
        </div>
        <div className="overflow-y-scroll h-[340px] mx-6 mt-20 text-start ">
            <p className="font-bold">Main Activities</p>
            <ul className=" list-disc mx-5 text-neutral-600">{activities}</ul>
            <br></br>
            <p className="font-bold inline">Techs used: </p>
            <span className="inline" >{props.experience.techs}</span>
            <br></br>            <br></br>
            {props.experience.links.length==0 ? <br></br> : <p><b>Links on Project:</b></p>}

            {/* {props.experience.links==[] ? <br></br> : {props.experience.links}} */}
            {links}
        </div>
        </div>

        <div className=" rounded-br-lg p-3 border-t-2 border-slate-400 shadow-[inset_0_10px_9px_-7px_rgba(255,255,255,0.4)]">
            <div className=" flex flex-row items-center"> 
            </div>
        </div>
    </div>
  )
}