import { useEffect, useState } from "react";
import useDimensions from "./useDimensions";

/**
 * Determine if the screen size is within the specified breakpoint (src/themes/index.ts).
 *
 * @param index
 * @returns
 */

const breakpoints = ["480px", "768px", "1024px"];

export default function useInBreakpoint(index , useHeight) {
  const { width, height } = useDimensions();
  const [inBreakpoint, setInBreakpoint] = useState(false);

  useEffect(() => {
    setInBreakpoint((useHeight ? height : width) < parseInt(breakpoints[index]));
  }, [width, height, useHeight, index]);

  return inBreakpoint;
}
