
import React from "react";
import NavbarItem from "./NavbarItem";
import LevelCard from "./LevelCard";
// import StatBar from "./StatBar";
import {Link} from 'react-router-dom'

import Book from '../../assets/images/book4.png';
import Coin from '../../assets/images/coins.png';
import Sword from '../../assets/images/sword.png';
import StatBar from "./StatBar";
import useTaskbarHeight from "../../hooks/useTaskbarHeight";
import useInBreakpoint from "../../hooks/useInBreakpoint";
import useIsLandscape from "../../hooks/useIsLandscape";
import useDimensions from "../../hooks/useDimensions";

const route= ""
export default function Navbar() {
  const taskbarHeight = useTaskbarHeight();
 
  function useIsMinLandscape(){
    const { width, height } = useDimensions();
    console.log(width)
    
    return width >660
  }
  const isMobile = useInBreakpoint(0, useIsLandscape());
  const isMidScreen= useInBreakpoint
  const isLandscape= useIsMinLandscape();
  
  return (
    <div class="grid grid-cols-8 divide-x fixed bottom-0 bg-slate-100 border border-slate-500 p-1 shadow-inner">
        <div class={
          isMobile 
          ? isLandscape ? "flex col-span-2 items-center bg-gradient-to-b from-neutral-600 to-neutral-800 border border-gray-200 rounded-xl shadow-md" : "hidden"
          : " lg:flex hidden pl-0 tablet:col-span-4 laptop:col-span-2 items-center bg-gradient-to-b from-neutral-600 to-neutral-800 border border-gray-200 rounded-xl shadow-md"
        }>
          <LevelCard
              age= "23"
            />
          <div class="inline-block grid grid-rows-2 ml-3 ">
            <p class={
              isMobile && isLandscape 
              ? "row-span-1 text-left text-amber-50 text-base  drop-shadow-xl shadow-black"
              : "row-span-1 text-left text-amber-50 text-base laptop:text-lg desktop:text-2xl drop-shadow-xl shadow-black"}>Hermit</p>
            <p class={
              isMobile && isLandscape 
              ? "row-span-1 text-left text-amber-50 text-base  drop-shadow-xl shadow-black"
              : "row-span-1 text-left text-amber-50 text-base laptop:text-lg desktop:text-2xl drop-shadow-xl shadow-black"}>pearlyn</p>
          </div>
        </div>

        <div  class={
          isMobile 
          ? isLandscape 
            ? 'flex col-span-3 mr-2 items-center justify-center bg-gradient-to-b from-neutral-600 to-neutral-800 border border-gray-200 rounded-xl shadow-md' 
            : 'hidden' 
          :'hidden lg:flex laptop:col-span-4 mr-4 items-center justify-center bg-gradient-to-b from-neutral-600 to-neutral-800 border border-gray-200 rounded-xl shadow-md'} >
          <div class="w-1/3 ml-5 ">
            <StatBar title="HP"  
            color="h-5 w-full bg-red-600 rounded-md dark:bg-blue-500"
            progress= "1111/1111" />
          </div>
          <div class="w-1/3  mx-1 mr-4">
            <StatBar title="MP" 
            color="h-5 w-3/5 bg-blue-600 rounded-md dark:bg-blue-500"
            progress= "537/895" />
          </div>
          <div class="w-1/3  mx-1 mr-5">
            <StatBar title="EXP."
            color="h-5 w-4/5 bg-yellow-300 rounded-md dark:bg-blue-500"
            progress= "579/666" />
          </div>
        </div>
      
      <div class={isMobile && isLandscape ? "flex  h-[60px] col-span-3 items-center justify-center" : "flex col-span-8 h-[80px] tablet:h-[90px] laptop:h-[110px] tablet:col-span-4 laptop:col-span-2 items-center justify-center"}>
        <div class="grid grid-cols-3 divide-x">
          <div>
          <Link to='/about-me'>
            <NavbarItem
            title='About Me'
            img= {Coin}
            link='about-me'
            styling={ isMobile && isLandscape 
              ? 'my-1 place-items-center h-[60px] grid max-w-sm p-1 bg-gradient-to-r from-red-500 to-red-600 border border-gray-200  rounded-xl shadow-md '
              : 'my-1 place-items-center h-[80px] tablet:h-[90px] laptop:h-[110px] grid max-w-sm p-1 bg-gradient-to-r from-red-500 to-red-600 border border-gray-200  rounded-xl shadow-md '}
            />
          </Link>
          </div>
      
          <div>
          <Link to='/skills'>
            <NavbarItem
            title='Skills'
            img= {Sword}
            link='skills'
            styling={ isMobile && isLandscape 
              ? 'my-1 place-items-center h-[60px] grid max-w-sm p-1 bg-gradient-to-r from-green-500 to-green-600  border border-gray-200  rounded-xl shadow-md '
              : 'my-1 place-items-center  h-[80px] tablet:h-[90px] laptop:h-[110px] grid max-w-sm p-1 bg-gradient-to-r from-green-500 to-green-600 border border-gray-200 rounded-xl shadow-md '}
            />
          </Link>
          </div>

          <div>
            <Link to="/projects">
              <NavbarItem
              title='Projects'
              img= {Book}
              link='projects'
              styling={ isMobile && isLandscape 
                ? 'my-1 place-items-center h-[60px] grid max-w-sm p-1 bg-gradient-to-r from-blue-500 to-blue-600  border border-gray-200  rounded-xl shadow-md '
              : 'my-1 place-items-center  h-[80px] tablet:h-[90px] laptop:h-[110px] grid max-w-sm p-1 bg-gradient-to-r from-blue-500 to-blue-600 border border-gray-200 rounded-xl shadow-md '}
              />
            </Link>
          </div>  
        </div>
      </div>

   </div>
  );
}