import Logobootstrap from "../assets/icons/skill-bootstrap.svg";
import Logocss from "../assets/icons/skill-css.svg";
import Logofigma from "../assets/icons/skill-figma.svg";
import Logofirebase from "../assets/icons/skill-firebase.svg";
import Logoframer from "../assets/icons/skill-framer.svg";
import Logogit from "../assets/icons/skill-git.svg";
import Logogithub from "../assets/icons/skill-github.svg";
import Logohtml from "../assets/icons/skill-html.svg";
import Logojira from "../assets/icons/skill-jira.svg";
import Logojquery from "../assets/icons/skill-jquery.svg";
import Logojs from "../assets/icons/skill-js.svg";
import Logonextjs from "../assets/icons/skill-nextjs.svg";
import Logonodejs from "../assets/icons/skill-nodejs.svg";
import Logooffice from "../assets/icons/skill-office.svg";
import Logoreactjs from "../assets/icons/skill-reactjs.svg";
import Logosass from "../assets/icons/skill-sass.svg";
import Logots from "../assets/icons/skill-ts.svg";
import Logovscode from "../assets/icons/skill-vscode.svg";
import Logovuejs from "../assets/icons/skill-vuejs.svg";
import Logovuepress from "../assets/icons/skill-vuepress.png";

const skills = [
    {
      name: "html",
      label: "HTML",
      url: "https://developer.mozilla.org/en-US/docs/Web/HTML",
      color: "#e34f26",
      category: "Fundamentals",
      logo: Logohtml,
      skillpt: 10
    },
    {
      name: "css",
      label: "CSS",
      url: "https://developer.mozilla.org/en-US/docs/Web/CSS",
      color: "#0170ba",
      category: "Fundamentals",
      logo: Logocss,
      skillpt: 10
    },
    {
      name: "js",
      label: "JavaScript",
      url: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
      color: "#ffc107",
      category: "Fundamentals",
      logo: Logojs,
      skillpt: 10
    },
    {
      name: "ts",
      label: "TypeScript",
      url: "https://www.typescriptlang.org/",
      color: "#3178c6",
      logo: Logots,
      skillpt: 10
    },
    {
      name: "jquery",
      label: "jQuery",
      url: "https://jquery.com/",
      color: "#0868ac",
      category: "Libraries/Frameworks",
      logo: Logojquery,
      skillpt: 10
    },
    {
      name: "reactjs",
      label: "ReactJS",
      url: "https://reactjs.org/",
      color: "#00bcd4",
      category: "Libraries/Frameworks",
      logo: Logoreactjs,
      skillpt: 10
    },
    {
      name: "nextjs",
      label: "NextJS",
      url: "https://nextjs.org/",
      color: "#000",
      category: "Libraries/Frameworks",
      logo: Logonextjs,
      skillpt: 10
    },
    {
      name: "vuejs",
      label: "Vue",
      url: "https://vuejs.org/",
      color: "#41b883",
      category: "Libraries/Frameworks",
      logo: Logovuejs,
      skillpt: 10
    },
    {
      name: "vuepress",
      label: "VuePress",
      url: "https://vuepress.vuejs.org/",
      color: "#41b883",
      category: "Libraries/Frameworks",
      logo: Logovuepress,
      skillpt: 10
    },
    {
      name: "nodejs",
      label: "NodeJS",
      url: "https://nodejs.org/",
      color: "#8cc84b",
      category: "Fundamentals",
      logo: Logonodejs,
      skillpt: 10
    },
    {
      name: "bootstrap",
      label: "Bootstrap",
      url: "https://getbootstrap.com/",
      color: "#563d7c",
      category: "Libraries/Frameworks",
      logo: Logobootstrap,
      skillpt: 10
    },
    {
      name: "sass",
      label: "Sass",
      url: "https://sass-lang.com/",
      color: "#cd6799",
      category: "Libraries/Frameworks",
      logo: Logosass,
      skillpt: 10
    },
    {
      name: "vscode",
      label: "Visual Studio Code",
      url: "https://code.visualstudio.com/",
      color: "#0075b7",
      category: "Tools",
      logo: Logovscode,
      skillpt: 10
    },
    {
      name: "office",
      label: "MS Office",
      url: "https://www.office.com/",
      color: "#eb3c00",
      category: "Tools",
      logo: Logooffice,
      skillpt: 10
    },
    {
      name: "figma",
      label: "Figma",
      url: "https://www.figma.com/",
      color: "#a259ff",
      category: "Tools",
      logo: Logofigma,
      skillpt: 10
    },
    {
      name: "firebase",
      label: "Firebase",
      url: "https://firebase.google.com/",
      color: "#ffa714",
      logo: Logofirebase
    },
    {
      name: "framer",
      label: "Framer Motion",
      url: "https://www.framer.com/motion/",
      color: "#67dbff",
      category: "Libraries/Frameworks",
      logo: Logoframer,
      skillpt: 10
    },
    {
      name: "git",
      label: "Git",
      url: "https://git-scm.com/",
      color: "#f05133",
      logo: Logogit,
      skillpt: 10
    },
    {
      name: "github",
      label: "Github",
      url: "http://github.com/",
      color: "#000",
      logo: Logogithub,
      skillpt: 10
    },
    {
      name: "jira",
      label: "Jira",
      url: "https://www.atlassian.com/software/jira",
      color: "#2684ff",
      category: "Tools",
      logo: Logojira,
      skillpt: 10
    },
  ];
  
  export default skills;
  