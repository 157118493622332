import React, { useState } from 'react';
import book from '../../../src/assets/maplestory/old_book.png'
import CloseIcon from '@mui/icons-material/Close';
import {Link} from 'react-router-dom'
import skills from '../../data/skills'
import StyledAccordion from './StyledAccordion';
import ProjectDetails from './ProjectDetails';
import experience from '../../data/experience';
const uniqueCategories = new Set(skills.map((skill) => skill.category).filter((category) => !!category));
const categories = [...Array.from(uniqueCategories), "Misc"];

export default function ProjectBox() { 
    const [selectedCategory, setCategory] = useState('work');
    var filteredSkills= skills.filter(skill=> skill.category == selectedCategory)

    let skillList=filteredSkills.map((skill,index)=>{
        return (
            <a  href={skill.url}>
                <div className="flex flex-row  items-center  place-content-between shadow-inner mx-2 mb-1 bg-slate-100 ">
                <div className="basis-1/4 grid place-items-center  ">
                    <a href={skill.url}>
                        <img className= 'm-2 p-1 border-2 rounded-lg border-neutral-600 h-14 w-14' src={skill.logo}/>
                    </a>
                </div>
                <div className="basis-3/4 p-2 text-start">
                        <p className="bg-slate-200 p-1 mb-1 rounded-sm">{skill.label}</p>
                        <p className="bg-slate-200 p-1 rounded-sm">{skill.skillpt}</p>
                </div>
                </div>
            </a>
        )
    })

    function isCategory(cat){
        return selectedCategory ==  cat
    }

    const [projectDetails, setProjectDetails] = useState(experience["work"][0]);
  return (
    
    <div className="flex flex-row h-7/8 mt-[90px]">
        <div className="h-1/2 rounded-md  bg-slate-300 border border-gray-800 ring-2 ring-gray-500 ml-20 w-1/4">
            <div className=" rounded-md shadow-inner mx-3 my-2  bg-slate-100  border-4 border-gray-300 ring-2 ring-slate-600 	">
                <div className=" flex flex-row items-center  place-content-between "> 
                <p className='mx-2 text-sm font-mono font-uppercase'>WORK & PROJECTS</p>
                <button className="bg-sky-300 text-sm border border-neutral-700 m-1 mx-2 rounded-md">
                <Link to="/">
                    <CloseIcon sx={{ color: '#f2f0e9' }}></CloseIcon>      
                </Link>
                </button>
                </div>
            </div>
            <div className=" rounded-t-md shadow-inner mx-2 bg-slate-100 border-b-8 border-red-900  	">
                <div className=" flex flex-row items-end "> 
                <button 
                onClick={() => setCategory('work')}
                className={ isCategory('work') 
                ? 'bg-red-300 pt-1 text-neutral-100 font-bold rounded-t-lg w-48 shadow-inner border-2 border-neutral-700 text-lg font-mono' 
                : 'bg-stone-300  text-neutral-100 font-bold rounded-t-lg w-48 shadow-inner border-2 border-neutral-700 text-lg font-mono'}>Experience</button>
                <button 
                onClick={() => setCategory('projects')}
                className={ isCategory('projects') 
                ? 'bg-red-300 pt-1 text-neutral-50 font-bold rounded-t-lg w-48  shadow-inner border-2 border-neutral-700 text-lg font-mono' 
                : 'bg-stone-300 text-neutral-50 font-bold rounded-t-lg w-48 shadow-inner border-2 border-neutral-700 text-lg font-mono'}>Projects</button>
                </div>
            </div>

                <div className="overflow-y-scroll h-96 mr-2">
                   <StyledAccordion selected={selectedCategory} onSelect={setProjectDetails}/>
                </div>
       
            <div className="rounded-b-md p-3 border-t-2 border-slate-400 shadow-[inset_0_10px_9px_-7px_rgba(255,255,255,0.4)]">
            <div className=" flex flex-row items-center"> 
            </div>
        </div>
        </div>
        <ProjectDetails experience={projectDetails }/>
            
    </div>

 
  );
}