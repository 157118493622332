
import React from "react";
import useInBreakpoint from "../../hooks/useInBreakpoint";
import useIsLandscape from "../../hooks/useIsLandscape";
import useDimensions from "../../hooks/useDimensions";


function activate(link){
  // alert(link)
}

export default function NavbarItem(props) {
    function useIsMinLandscape(){
      const { width, height } = useDimensions();
      console.log(width)
      
      return width >660
    }
    const isMobile = useInBreakpoint(0, useIsLandscape());
    const isLandscape= useIsMinLandscape();
    
    
  return (
    <button onClick={activate.bind(this,props.link)}>
    <div class={props.styling} >
            <img class={
              isMobile && isLandscape? "object-center place-content-center w-1/5 m-2"
              : "object-center place-content-center w-2/5 laptop:w-3/5 m-2"}  src={props.img} alt=''/>
            <h5 class="absolute bottom-5 text-center font-black font-mono text-amber-50  desktop:text-xl tracking-wide uppercase drop-shadow-xl shadow-black">{props.title}</h5>
    </div>
   </button>
  );
}