import { useEffect, useState } from "react";
import useInBreakpoint from "./useInBreakpoint";
import useIsLandscape from "./useIsLandscape";

const sizes = [0, 4, 8, 12, 16, 24, 32, 48, 64, 96, 128, 256, 512]; 

export default function useTaskbarHeight() {
  const isLandscape = useIsLandscape();
  const isMobile = useInBreakpoint(0, isLandscape);
  const [height, setHeight] = useState(sizes[8]);

  useEffect(() => {
    setHeight(sizes[isMobile && isLandscape ? 7 : 8]);
  }, [isLandscape, isMobile]);

  return height;
}
