import React from "react";
import useInBreakpoint from "../../hooks/useInBreakpoint";
import useIsLandscape from "../../hooks/useIsLandscape";
import useDimensions from "../../hooks/useDimensions";

export default function LevelCard(props) {
   
  function useIsMinLandscape(){
    const { width, height } = useDimensions();
    console.log(width)
    
    return width >660
  }
  const isMobile = useInBreakpoint(0, useIsLandscape());
  const isLandscape= useIsMinLandscape();
  return (
   <div  class='flex h-full items-center p-2  max-w-sm bg-gradient-to-r from-stone-800 to-stone-900 rounded-xl shadow-md' >
    <h5 class={ isMobile && isLandscape
      ? "inline-block text-left font-black font-mono text-amber-50 text-;g uppercase drop-shadow-xl shadow-black"
      : "inline-block text-left font-black font-mono text-amber-50 sm:text-3xl lg:text-5xl uppercase drop-shadow-xl shadow-black"}>LV.</h5>
    <div class="inline-flex mx-1  bg-amber-400 rounded-md shadow-md">
        <h5 class={
          isMobile && isLandscape ? 
          "p-1 text-center font-black tabular-nums text-amber-50 text-sm drop-shadow-xl shadow-black"
          :"p-1 sm:p-2 text-center font-black tabular-nums text-amber-50 sm:text-lg lg:text-2xl drop-shadow-xl shadow-black"}>{props.age[0]}</h5>
    </div>
    <div class="inline-block mx-1 bg-amber-400 rounded-md shadow-md">
    <h5 class={
          isMobile && isLandscape ? 
          "p-1 text-center font-black tabular-nums text-amber-50 text-sm drop-shadow-xl shadow-black"
          :"p-1 sm:p-2 text-center font-black tabular-nums text-amber-50 sm:text-lg lg:text-2xl drop-shadow-xl shadow-black"}>{props.age[1]}</h5>
    </div>
 
   </div>
 
  );
}